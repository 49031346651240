import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styledScrollbar from './styled-scrollbars.module.scss';
import sidebarStyles from './sidebar.module.scss';

export default class StyledScrollbars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIOs: false
    };
  }

  componentDidMount() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.setState({ isIOs: isSafari && isIOS });
  }

  render() {
    const { children, onScroll, iOSHeight } = this.props;

    const style =
      this.state.isIOs && iOSHeight !== undefined ? { height: this.props.iOSHeight } : {};

    return (
      <Scrollbars
        onScroll={onScroll}
        className={`${styledScrollbar.styledScrollbar} ${sidebarStyles.fixipad}`}
        style={style}
        renderThumbVertical={({ style, ...props }) => (
          <div
            style={{
              ...style,
              backgroundColor: 'rgba(50,50,50,1)',
              borderRadius: '3px'
            }}
            {...props}
          />
        )}
      >
        {children}
      </Scrollbars>
    );
  }
}
